import * as React from "react";
import img from "../images/driveline/trackter.jpeg";
import a1 from "../images/driveline/ball_joint.png";
import a2 from "../images/driveline/drive_shaft_1.png";
import a3 from "../images/driveline/drive_shaft_2.png";
import b1 from "../images/driveline/drive_shaft_3.png";
import b2 from "../images/driveline/drive_shaft_4.png";
import b3 from "../images/driveline/steering_systems.png";

import { MainLayout } from "../layouts/MainLayout";

// markup
const MilitaryVehicleParts = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>DRIVELINE PARTS</h1>
                  <h3>Trucks, Mining & Armoured Vehicles</h3>
                </div>
                <p className="page-description">
                  We are the certified component supplier and development
                  partner in driveline systems, specializing in sale of single
                  parts as OEM supplier, catering to diverse industries from
                  agriculture to mining.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
                <section className="products">
                  <h5>Product Images -  Joints & Shafts</h5>
                  <ul>
                    <li>
                      <figure>
                        <img src={a1} alt={a1} />
                        <p>Ball Joint</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a2} alt={a2} />
                        <p>Drive Shaft</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={a3} alt={a3} />
                        <p>Drive Shaft</p>
                      </figure>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <figure>
                        <img src={b1} alt={b1} />
                        <p>Drive Shaft</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={b2} alt={b2} />
                        <p>Drive Shaft</p>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <img src={b3} alt={b3} />
                        <p>Steering System</p>
                      </figure>
                    </li>
                  </ul>
                  <small>Copyright of Scientific World Journal, Bong-Su Sin, Kwon-Hee Lee</small>
                </section>
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Products</h3>
                  <ul>
                    {/* <li>
                      <p className="list-title">
                        <span>Axles & Transmissions</span>
                        We service axles and transmissions for off-highway
                        applications. The portfolio also includes Sub-Assemblies
                        and special transmissions, as well as lifting parts used
                        in material handling applications.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">Kessler Axles,</span>
                      <span className="brands">Kessler Transmissions,</span>
                      <span className="brands">Kessler T-Cases,</span>
                      <span className="brands">Dana Axles,</span>
                      <span className="brands">Dana Transmissions,</span>
                      <span className="brands">Carraro Axles,</span>
                      <span className="brands">Allison Transmissions,</span>
                      <span className="brands">Flender Axles,</span>
                      <span className="brands">Siemens Products,</span>
                      <span className="brands">Reggiana Riduttori Sub-Assemblies</span>
                    </li> */}
                    {/* <li>
                      <p className="list-title">
                        <span>Electrical Drives</span>
                        We offer drive technologies and automation solutions of
                        leading brands in electrical drives. The systems can
                        handle capacities ranging from 500 gms to 2 tonnes.
                      </p>
                      <p className="leading-brand">Leading Brands Handled,</p>
                      <span className="brands">Siemens Drives,</span>
                      <span className="brands">SEW Usocome Drives,</span>
                      <span className="brands">Woelfert Drives,</span>
                      <span className="brands">Flender Drives,</span>
                      <span className="brands">Tratos Cables,</span>
                      <span className="brands">Prysmian Cables,</span>
                      <span className="brands">Huebner</span>
                    </li> */}
                    <li>
                      <p className="list-title">
                        <span>Steering Systems & Elements, Ball-joints</span>
                        We offer a range of systems and parts, to govern and
                        control the movements with accuracy. These parts are
                        developed and manufactured as per demand.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">AVT (made on order)</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Drive-shafts, CV Joints, Cardans</span>
                        The drive shafts are intricately chosen to carry power
                        and deliver powerful transmission capabilities as per
                        requirements. These parts are developed and manufactured
                        as per demand.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">AVT (made on order)</span>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Engines</span>
                        We offer dependable power solutions for mining and
                        cement industries, including for opencast and
                        underground mining operations. Our range of offerings
                        deliver excellent productivity ensuring smooth
                        operations.
                      </p>
                      <p className="leading-brand">Leading Brands Handled </p>
                      <span className="brands">Cummins Engines,</span>
                      <span className="brands">MAN Engines,</span>
                      <span className="brands">Deutz Engines,</span>
                      <span className="brands">Mercedes Engines,</span>
                      <span className="brands">Volvo Engines,</span>
                      {/* <span className="brands">Cummins Engine Parts</span> */}
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default MilitaryVehicleParts;
